import React from "react"
import logo from "../assets/icons/logo.svg"
import { Link } from "gatsby"

const Footer = (props) => {
  return (
    <footer className={`footer ${props.content?.classes}`}>
      <div className="container">
        <div className="footer__grid">
          <div className="footer__logo">
            <div>
            <img src={logo} className="logo" alt="logo"/>
            <p className="footer__about">Navigate your business with Onport, a multi-vendor marketplace solution to scale your operations.</p>
            </div>
          </div>
          <div className="footer__col">
            <h2>Referrals</h2>
            <Link to="https://referral-program.onport.com/?utm_campaign=Onport_referral_program&utm_source=website" className={"link__dropdown"}>Referral Program</Link>
          </div>
          <div className="footer__col">
            <h2>Product</h2>
            <Link to="/partners" className={"link__dropdown"} >Integrations</Link>
            <Link to="/features" className={"link__dropdown"} >Features</Link>
          </div>
          <div className="footer__col">
            <h2>Resources</h2>
            <Link to="/casestudies" className={"link__dropdown"} >Case studies</Link>
            <Link to="/blog" className={"link__dropdown"} >Blog</Link>
            <Link to="https://composable.jetti.io/" className={"link__dropdown"} >Dev Docs</Link>
            <Link to="/about" className={"link__dropdown"} >About</Link>
            <Link to="https://onport.factorialhr.com/" target="_blank" className={"link__dropdown"} >Careers</Link>
          </div>
          <div className="footer__col">
            <h2>Help</h2>
            <Link to="/terms" className={"link__dropdown"} >Terms</Link>
            <Link to="/privacy" className={"link__dropdown"} >Privacy</Link>
          </div>
        </div>
        <div className="footer__info">
          <div>
            <span>© Onport 2024</span>
            <span className="footer__address">The Bower, London, EC1V 9NR, United Kingdom</span>
          </div>
          <div>
            <a href="https://www.youtube.com/channel/UCpHTLMOOXt-mhrkxNyWAPiw"><img src={require("../assets/icons/youtube.svg")} alt="jetti-youtube" className="footer__social-icon" /></a>
            <a href="https://twitter.com/onport_hq"><img src={require("../assets/icons/twitter.svg")} alt="jetti-twitter" className="footer__social-icon" /></a>
            <a href="https://www.linkedin.com/company/onport/"><img src={require("../assets/icons/linkedin.svg")} alt="jetti-linkedin" className="footer__social-icon" /></a>
          </div>
        </div>
      </div>
    </footer>
    )
}

export default Footer
